import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';

// const root = ReactDOM.createRoot(document.getElementById('root'));

const cache = createCache({
  key: 'css',
  prepend: true,
});

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

root.render(
  <React.StrictMode>
  <App />
</React.StrictMode>  

);
// root.render(
// <StyledEngineProvider injectFirst>
//     <CacheProvider value={cache}>
//     <ThemeProvider theme={theme}>
//       <React.StrictMode>
//         <App />
//       </React.StrictMode>      
//     </ThemeProvider>    
//     </CacheProvider>
//   </StyledEngineProvider>

// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
