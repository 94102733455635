import * as React from 'react';
import { createContext, useState, useContext, useEffect } from "react";
import { FaCopy } from "react-icons/fa";
import { AiOutlineClear } from "react-icons/ai";
import { MdOutlineAutorenew, MdPublish } from "react-icons/md";
import { BiMessageRoundedEdit } from "react-icons/bi";
import { RiSave3Fill, RiShareForwardLine } from "react-icons/ri";


import { TbPrompt } from "react-icons/tb";

import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "@clerk/clerk-react";
import { UserButton } from '@clerk/clerk-react';
import AceEditor from "react-ace";
import { CirclesWithBar } from 'react-loader-spinner';
import { faker } from '@faker-js/faker';
// import {MySelect} from './components/common/Select';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from "react-select";
import Validator from "../validator.d.js";
// import TextField from '@mui/material/TextField';
import Input from '@mui/base/Input';
import { useForm, Controller, useController } from "react-hook-form";
import { AppContext } from '../Context.js';
import SharePopUp from './SharePopUp.js'



import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"
// import { TextField,TextareaAutosize } from "@mui/material";

function Editor({context}) {
  // state for storing the prompt and the completion
  const { state, setState, utils, setUtils } = context;
  // const [prompt, setPrompt] = useState("");
  const [appAlias, setAppAlias] = useState("");
  const [completion, setCompletion] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(JSON.stringify(state))
  // console.log(`${state.projectName}-${state.appName}`)

  const { isLoaded, isSignedIn, user } = useUser();
  const { userId, sessionId, getToken } = useAuth();

  const [projectName, setProjectName] = useState("");
  const [appName, setAppName] = useState("");
  const projectOptions = [];
  const appOptions = [];
  const backendApiBaseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL
  // const [initState] = useState({projectName:"",errors:""});
  // const [state, setState] = useState(stateContext);
  // const ReferenceDataContext = createContext({ state, projectName, appName});
  const { register, handleSubmit, watch, formState: { errors }, control } = useForm({
    defaultValues: {
      projectName: state.projectName,
      appName: state.appName
    }
  });

  const scrollToSection = id => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
    // if (scrollRef.current.length) {
    //   scrollRef.current[id].scrollIntoView();
    // }
  };
  const handlePublishClick = async () => {
    if (!state.code) {
      return
    }
    scrollToSection("loading")
    state.loading = `Publishing App ${state.projectName} ${state.appName} ${state.app_version_dev} ...`
    setState({ ...state });
    if (!state.appAlias) {
      if (state.projectAppList) {
        let currentApp = state.projectAppList.find(app => app[1] === state.appName)
        if (currentApp) {
          state.appAlias = currentApp[3]
          setState({ ...state })
        }
      }
      if (!state.appAlias) {
        state.appAlias = faker.internet.domainWord()
        setState({ ...state })
      }
    }
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    const response = await axios.post(
      `${backendApiBaseUrl}/save_project_app`,
      { projectName: state.projectName, appName: state.appName, user_id: userId, app_alias: state.appAlias, app_code: state.code, app_version: state.app_version_dev },
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );
    // let data = JSON.parse(response.data)
    // for (let item of data) {
    //   projectOptions.push({value: item[0], label: item[0]})
    //   appOptions.push({value: item[1], label: item[1]})
    // }    
    state.projectAppList = null
    state.loading = ""
    state.app_version = state.app_version_dev
    setState({ ...state });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleClear = () => {
    console.log("handleClear")
    console.log(state.code)
    state.loading = "Clearing App..."
    state.code = "";
    state.completion = "";
    state.app_version_dev = ""
    setState({ ...state });
    state.loading = ""
    setState({ ...state });

  };

  const [toggleShare, setToggleShare] = useState(false);
  // const handleShare = () => {
  //   navigator.clipboard.writeText(state.code);
  // };

  const recreate = () => {
    console.log("recreate")
    state.code = "";
    state.completion = "";
    state.app_version_dev = ""
    // setState({ ...state});    
    console.log(state.code)
    // document.getElementById('submitCompletion').click();
    console.log(utils)
    utils.handleCompletionClick(state)
  };

  const prompt = () => {
    console.log("prompt")
    scrollToSection("prompt-text")
    // setState({ ...state});    
    // console.log(state.code)
  };

  const saveCode = () => {
    console.log("saveCode")
    utils.handleCompletionClick(state)
  }
  const [toggleImprovePrompt, setToggleImprovePrompt] = useState(false);
  const [promptImprovement, setPromptImprovement] = useState("");
  const handleImprovePrompt = (improvement) => {
    console.log(`improvePrompt ${improvement}`)
    state.prompt = state.prompt + "\n" + "---" + "\n" + improvement
    utils.handleCompletionClick(state)
    // setToggleImprovePrompt(false)
    state.showImprovePrompt=false; 
    setState({...state})
  }

  const [toggleThemeSelect, setToggleThemeSelect] = useState(false);

  const [currentTheme, setCurrentTheme] = useState(null);

  const handleThemeSelect = (event) => {
    var a = event.currentTarget;
    var theme = a.getAttribute("data-set-theme")
    var mystr = state.code
    var res = mystr.match(/data-theme=\".*\"/g);
    var str = ""
    if(res){
      str = mystr.replace(res, `data-theme="${theme}"`);
    } else {
      str = mystr.replace('<body', `<body data-theme="${theme}" `);
    }
    state.code = str
    console.log(a.innerHTML)
    setCurrentTheme(a.innerHTML)
    setState({ ...state })
    setToggleThemeSelect(false)
  }

  const loadCurrentTheme = (themeList) => {

    // var a = event.currentTarget;
    var mystr = state.code
    var res = mystr.match(/data-theme=\".*\"/g);
    var str = ""
    if(res){
      console.log(res)
      str = res[0].replace(`data-theme="`, "").replace(`"`, ``);
      for (let theme of themeList) {
        if(theme.includes(str)){
          console.log(str)
          setCurrentTheme(theme)
        }      
      }
    }else{
      setCurrentTheme("")
    }
  }  


  const [isFullscreen, setIsFullscreen] = useState(false);

  const [isFullscreenCode, setIsFullscreenCode] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    scrollToSection("appScreenBuilder")
  };

  const toggleFullscreenCode = () => {
    setIsFullscreenCode(!isFullscreenCode);
  };

  // const handleSubmit = (e) => {
  //   // Handle Validation on submit
  //   console.log("handleSubmit2"+JSON.stringify(state))
  //   Validator.validate(state)
  //     .then(data => {
  //       alert("Success");
  //       // setState(initState);
  //     })
  //     .catch(errors => {
  //       console.log("error handleSubmit"+JSON.stringify(errors))
  //       // setState({ errors: errors });
  //       state["errors"] = errors
  //     });
  // }  
  const handleSubmit2 = (e) => {
    // Handle Validation on submit
    console.log("handleSubmit2" + JSON.stringify(state))
  }

  const handleDirty = (e) => {
    // Handle Validation on touch

    const { name, value } = e.target;

    const isValid = Validator[name].validate(value, {
      verbose: true,
      values: state
    });

    if (isValid !== true) {
      // setState({
      //   errors: { ...state.errors, [name]: isValid }
      // });
      state["errors"] = { ...state.errors, [name]: isValid };
    } else {
      // setState({
      //   errors: { ...state.errors, [name]: undefined }
      // });
      state["errors"] = { ...state.errors, [name]: undefined };
    }
  }
  const handleSelectCompletion = async (data) => {
    setCompletion(data.app_code);
    setCode(data.app_code);
    state.code = data.app_code;
    state.completion = data.app_code;
    state.app_version_dev = data.app_version;
    state.latestVersions = [];
    if (!state.app_version_dev) {
      handlePublishClick().then(v => console.log('First Publishing...'))
    }        
    setState({ ...state });
    // state['code'] = response.data

  }

let themeList = [`🌝  light`, `🌚  dark`, `🧁  cupcake`,`🐝  bumblebee`, `✳️  Emerald`, `🏢  Corporate`, `🌃  synthwave`,
`👴  retro`, `🤖  cyberpunk`, `🌸  valentine`, `🎃  halloween`, `🌷  garden`, `🌲  forest`, `🐟  aqua`, `👓  lofi`, 
`🖍  pastel`, `🧚‍♀️  fantasy`, `📝  Wireframe`, `🏴  black`, `💎  luxury`, `🧛‍♂️  dracula`,`🖨  CMYK`]

  // const handleStateChange = (e) => {
  //   console.log("handleStateChange");
  //   // console.log("handleStateChange"+JSON.stringify(e.target))
  //   // Handle Validation on touch

  //   const { name, value } = e.target;
  //   state[name] = value;
  //   console.log("handleStateChange"+name+"-"+value);
  // }
  // const Input = props => <components.Input {...props} required={props.selectProps.required} />

  useEffect(() => {
    console.log(`${state.projectName}-${state.appName}-${state.loading}`)
  }, [state.loading]);

  useEffect(() => {
    // window.scrollTo(0, document.body.scrollHeight)
  }, [isFullscreenCode, isFullscreen]);

  useEffect(() => {
    if (document.getElementById('promptImprove')) {
      document.getElementById('promptImprove').focus()
    }
  }, [toggleImprovePrompt]);

  useEffect(() => {
    loadCurrentTheme(themeList)
    // window.scrollTo(0, document.body.scrollHeight)
  }, [state.code,]);


  if (!isLoaded || !isSignedIn) {
    return null;
  }
  console.log(`${state.projectName}-${state.appName}`)
  return (
    <>
      {state.latestVersions && state.latestVersions.length > 0 &&
        <div className='bg-sky-500/95 fixed inset-0 flex items-center justify-center z-20 w-full'>
          <div class="grid grid-flow-col auto-cols-max overflow-x-scroll">
            {state.latestVersions && state.latestVersions.map(v =>
              <div className='mx-1'>          <iframe
                srcDoc={v.app_code}
                title={`Rendered HTML - ${v.app_version}`}
                className={`max-w-full min-w-full w-full border-2 border-gray-300 ${isFullscreen ? 'w-screen h-screen' : 'w-96 h-[60vh] mt-4 border-2 border-gray-300 rounded-md shadow-md rounded-3xl'}`}
                style={{
                  transformOrigin: '0 0',
                  overflowX: 'hidden',
                }}

              />
                <button onClick={() => handleSelectCompletion(v)}
                  className="disabled:opacity-75 w-full md:w-auto ml-0 md:ml-4 py-2 px-4 rounded-lg bg-gradient-to-r from-indigo-700 to-indigo-900 hover:from-indigo-800 hover:to-indigo-900 focus:from-indigo-800 focus:to-indigo-900 text-white shadow-md">
                  {`Select ${v.app_version}`}
                </button>
              </div>
            )}
          </div>
        </div>
      }
      {state.showImprovePrompt &&
        <div className='bg-sky-500/95 fixed inset-0 z-20 w-full'>
          <div class="gridx grid-flow-colx auto-cols-maxx w-full   mt-20 px-2">
            <div className='gridx grid-flow-colx auto-cols-maxx w-full  flex flex-col items-center justify-center'>
              <h1 className="text-3xl font-bold mb-4 text-center w-full">Enter The Prompt To Update Your App</h1>
              <h3>Current Prompt:</h3>
              <textarea

                value={state.prompt}
                // onBlur={v=>field.onBlur(handlePromptChange)}
                // onBlur={field.onBlur(v=>{setState({...state, prompt: v?.target.value})})}
                className="read-only:bg-gray-100 w-full mb-2 md:w-96 py-2 px-4 rounded-lg border-gray-400 border-2 focus:outline-none focus:border-indigo-500 shadow-md"
                rows={8}
                readOnly={true}
              // {...register("prompt", { required: true })} aria-invalid={errors.prompt ? "true" : "false"}
              >
              </textarea>
              <h3>Append Changes to the Prompt:</h3>
              <textarea
                id="promptImprove"
                value={promptImprovement}
                onChange={v => setPromptImprovement(v?.target.value)}
                // onBlur={field.onBlur(v=>{setState({...state, prompt: v?.target.value})})}
                className="read-only:bg-gray-100 w-full md:w-96 py-2 px-4 rounded-lg border-gray-400 border-2 focus:outline-none focus:border-indigo-500 shadow-md"
                rows={4}
                readOnly={state.code ? false : true}
              // {...register("prompt", { required: true })} aria-invalid={errors.prompt ? "true" : "false"}
              >
              </textarea>
              <br />
              <span>
                <button onClick={() => handleImprovePrompt(promptImprovement)}
                  className="disabled:opacity-75 mt-2 w-full md:w-auto ml-0 md:ml-4 py-2 px-4 rounded-lg bg-gradient-to-r from-indigo-700 to-indigo-900 hover:from-indigo-800 hover:to-indigo-900 focus:from-indigo-800 focus:to-indigo-900 text-white shadow-md">
                  Send
                </button>
                <button onClick={() => {state.showImprovePrompt=false; setState({...state})}}
                  className="disabled:opacity-75 mt-2 w-full md:w-auto ml-0 md:ml-4 py-2 px-4 rounded-lg bg-gradient-to-r from-indigo-700 to-indigo-900 hover:from-indigo-800 hover:to-indigo-900 focus:from-indigo-800 focus:to-indigo-900 text-white shadow-md">
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      }
      {toggleShare &&
      <SharePopUp 
      onClose={() => setToggleShare(false)} 
      embedValue={`<iframe width="560" height="315" src="https://api-dev.algochance.com:5001/${state.appAlias}" title="${state.appName}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`}
      onCopyEmbed={() => handleCopy(`<iframe width="560" height="315" src="https://api-dev.algochance.com:5001/${state.appAlias}" title="${state.appName}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`)}
      linkValue={"https://api-dev.algochance.com:5001/" + state.appAlias} 
      onCopyLink={() => handleCopy("https://api-dev.algochance.com:5001/" + state.appAlias)}
      ></SharePopUp>
        // <div className='bg-sky-500/95 fixed inset-0 flex items-center justify-center z-20'>
        //   <div class="h-[50vh] w-3/6 bg-gray-400">
              
        //     <div className='grid grid-cols-1 border-2 h-full rounded-lg border-gray-400 border-2'>
        //     <div className='basis-9/12 flex justify-center font-semibold'><h3>Share</h3></div>
        //       <div class="grid md:grid-flow-col grid-flow-row">
        //         <div className='sm:w-full basis-9/12 flex items-center'>
        //         <textarea rows={8} readOnly={true} value={`<iframe width="560" height="315" src="https://api-dev.algochance.com:5001/${state.appAlias}" title="${state.appName}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`} 
        //         className='read-only:bg-gray-100 w-full py-2 px-4 rounded-lg border-gray-400 border-2 focus:outline-none focus:border-indigo-500 shadow-md'></textarea>
        //         </div>
        //         <div className='sm:w-full basis-3/12 flex items-center'>
        //         <button onClick={() => handleCopy(`<iframe width="560" height="315" src="https://api-dev.algochance.com:5001/${state.appAlias}" title="${state.appName}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`)}
        //           className="flex items-center w-full disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800">
        //           <ImEmbed2 className="mr-2"></ImEmbed2>
        //           Copy Embed
        //         </button>
        //         </div>
        //       </div>
        //       <div class="grid md:grid-flow-col grid-flow-row">
        //       <div className='md:w-full basis-9/12 flex items-center'>
        //         <input readOnly={true} value={"https://api-dev.algochance.com:5001/" + state.appAlias} 
        //         className='read-only:bg-gray-100 w-full py-2 px-4 rounded-lg border-gray-400 border-2 focus:outline-none focus:border-indigo-500 shadow-md'></input>
        //         </div>
        //         <div className='md:w-full basis-3/12 flex items-center'>
        //         <button
        //           disabled={!state.code || state.loading}
        //           onClick={() => handleCopy("https://api-dev.algochance.com:5001/" + state.appAlias)}
        //           className="flex items-center w-full disabled:opacity-50 my-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800"
        //         >
        //           <FaCopy className="mr-2" />
        //           Copy Link
        //         </button>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <button onClick={() => setToggleShare(false)}
        //           className="disabled:opacity-75 w-full md:w-auto ml-0 md:ml-4 py-2 px-4 rounded-lg bg-gradient-to-r from-indigo-700 to-indigo-900 hover:from-indigo-800 hover:to-indigo-900 focus:from-indigo-800 focus:to-indigo-900 text-white shadow-md">
        //           Cancel
        //         </button>          
        // </div>
      }
      <div id="appScreenBuilder" className={`${isFullscreenCode? 'hidden' : 'relative w-full mr-4'}`}>
        {/* <button onClick={handlePublishClick}
            className="w-full md:w-auto ml-0 md:ml-4 py-2 px-4 rounded-lg bg-gradient-to-r from-indigo-500 to-indigo-600 hover:from-indigo-600 hover:to-indigo-700 focus:from-indigo-600 focus:to-indigo-700 text-white shadow-md">
            Publish
          </button>           */}
          <div className="relative flex flex-col items-center w-full flex items-center justify-center mt-4  sm:px-4">
        {toggleThemeSelect &&
          <div class="mt-10 z-10 absolute bg-indigo-700/90 right-14 bottom-20 overflow-y-auto shadow-2xl top-px dropdown-content h-48 w-52 rounded-b-box bg-base-200 text-base-content">
            <ul class="p-4 menu compact cursor-pointer">
              {themeList.map(v=>
                <li><a onClick={(e) => handleThemeSelect(e)} tabindex="0" data-set-theme={v.split(`  `)[1]} data-act-class="active">{v}</a></li>
              )}              
            </ul>
          </div>
        }

        <button onClick={() => setToggleThemeSelect(!toggleThemeSelect)}
          disabled={!state.code || state.loading}
          className="disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800 flex items-center md:mt-0 w-4/6 md:w-full md:mb-2">
          Change Theme <span dangerouslySetInnerHTML={{ __html: currentTheme }}></span>
        </button>
        </div>
        <div className="relative w-full px-4 mb-10 sm:mb-1">
          <button
            disabled={!state.code || state.loading}
            onClick={toggleFullscreen}
            className={`mr-20 disabled:opacity-50 ${isFullscreen ? 'fixed bottom-20 right-0' : ' absolute bottom-5 right-0'}  mt-4 mr-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800`}
          >
            {isFullscreen ? 'Close' : 'Preview'}
          </button>
          <iframe
            srcDoc={state.code}
            title="Rendered HTML"
            className={`max-w-full min-w-full w-full border-2 border-gray-300 ${isFullscreen ? 'w-screen h-screen' : 'w-96 h-[60vh] mt-4 border-2 border-gray-300 rounded-md shadow-md rounded-3xl'}`}
            style={{
              transformOrigin: '0 0',
              overflowX: 'hidden',
            }}
          />
        </div>
      </div>
      <div className={isFullscreen ? 'hidden' : 'w-full md:w-4/6 md:mr-4'}>
        {(state.code && !isFullscreenCode) &&
          <div class="relative rounded-xl overflow-auto p-8"><div class="flex items-center justify-center">
            <span class="relative inline-flex">
              <button type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-sky-500 bg-white dark:bg-slate-800 transition ease-in-out duration-150 ring-1 ring-slate-900/10 dark:ring-slate-200/20">
                <a href={"https://api-dev.algochance.com:5001/" + state.appAlias} target="_blank" rel="noreferrer">
                  Published {state.app_version} at: <p>{state.appAlias != "" && state.appAlias != undefined ? "https://api-dev.algochance.com:5001/" + state.appAlias : "Click on Publish to create a URL."}</p>
                </a>
              </button>
              {state.appAlias &&
                <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                </span>
              }
            </span>
          </div></div>
        }
        <div id="appCodeBuilder" className={`hidden lg:block ${isFullscreenCode?"relative flex flex-col items-center w-screen flex items-center justify-center h-[90vh]":"max-w-full min-w-full sm:w-full w-24"}`}>

            <div><h1 className="w-fullx z-40">Source Code - Make Changes And Save</h1></div>
          <div className={`relative ${isFullscreenCode?"w-screen w-fullx h-[90vh] flex items-center justify-center":"sm:w-full w-24"}`}>
            <button
              id="toggleFullscreenCode"
              disabled={!state.code || state.loading}
              onClick={toggleFullscreenCode}
              className={`disabled:opacity-50 ${isFullscreenCode ? 'fixed z-40' : 'absolute mt-4 mr-4 z-10'} py-2 px-4 right-0 bottom-12 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800`}
            >
              {isFullscreenCode ? 'Close' : 'Preview'}
            </button>
            <div className={` ${isFullscreenCode ? 'relative flex flex-col items-center w-screen flex items-center justify-center overflow-hidden' : 'sm:max-w-full sm:min-w-full sm:w-full w-24 sm:w-96x h-[80vh] md:h-[60vh] p-2x'}`}>
              <span>{state.app_version_dev}<span className='text-red-700 font-semibold'>{state.code != state.completion ? " - THE CODE HAS BEEN CHANGED. DON'T FORGET TO SAVE!" : ""}</span></span>
              <AceEditor
                value={state.code}
                mode="javascript"
                theme="github"
                name="UNIQUE_ID_OF_DIV"
                onChange={(value) => setState({ ...state, code: value })}
                editorProps={{ $blockScrolling: true }}
                lineNumbers={true}
                setOptions={{ useWorker: false }}
                readOnly={false}
                width='600px'
                height='300px'
                className={`${isFullscreenCode ? 'w-screen h-[90vh] w-fullx max-w-full w-full min-w-full max-h-full min-h-fullx h-fullx mb-5 mr-10x overflow-hidden' : 'border-2 border-gray-300 w-24 max-w-full min-w-full h-[40vh] md:h-[60vh] mt-1 border-2 border-gray-300 rounded-md shadow-md rounded-3xl'}`}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="appCodeBuilderCommands" className={` ${isFullscreen || isFullscreenCode? 'hidden' : 'flex flex-col items-center md:flex-col md:w-1/6 mr-4 w-full'}`}>
        <div className='relative flex items-center justify-center w-full'>
          <button
            disabled={!state.code || state.loading || state.code == state.completion}
            onClick={saveCode}
            className="relative disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800 flex items-center md:mt-0 w-4/6 md:w-full md:mb-2"
          >
            <RiSave3Fill className="mr-2" />
            Save
          {state.code != state.completion &&
            <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
              <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
            </span>
          }
          </button>
        </div>
        <button
          disabled={!state.code || state.loading || state.code != state.completion || state.app_version_dev == state.app_version}
          onClick={handlePublishClick}
          className="disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800 flex items-center md:mt-0 w-4/6 md:w-full md:mb-2"
        >
          <MdPublish className="mr-2" />
          Publish
        </button>
        <button
          disabled={!state.code || state.loading}
          onClick={recreate}
          className="disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800 flex items-center md:mt-0 w-4/6 md:w-full md:mb-2"
        >
          <MdOutlineAutorenew className="mr-2" />
          ReCreate
        </button>
        <button
          disabled={!state.code || state.loading}
          onClick={() => {state.showImprovePrompt=true; setState({...state});}}
          className="disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800 flex items-center md:mt-0 w-4/6 md:w-full md:mb-2"
        >
          <BiMessageRoundedEdit className="mr-2" />
          Improve
        </button>
        {/* <button
          disabled={!state.code || state.loading}
          onClick={() => handleCopy(state.code)}
          className="disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800 flex items-center md:mt-0 w-4/6 md:w-full md:mb-2"
        >
          <FaCopy className="mr-2" />
          Copy
        </button> */}
        <button
          id='clearApp'
          disabled={!state.code || state.loading}
          onClick={handleClear}
          className="disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800 flex items-center md:mt-0 w-4/6 md:w-full md:mb-2"
        >
          <AiOutlineClear className="mr-2" />
          Clear
        </button>
        <button
          disabled={!state.code || state.loading}
          onClick={() => setToggleShare(true)}
          className="disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800 flex items-center md:mt-0 w-4/6 md:w-full md:mb-2"
        >
          <RiShareForwardLine className="mr-2" />
          Share
        </button>
        {/* <button
          onClick={prompt}
          disabled={!state.code || state.loading}
          className="disabled:opacity-50 mt-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800 flex items-center md:mt-0 w-4/6 md:w-full md:mb-2"
        >
          <TbPrompt className="mr-2" />
          Prompt
        </button> */}
      </div>
      </>
  );
}

export default Editor;
