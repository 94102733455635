import * as React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { FaCopy } from "react-icons/fa";
import { AiOutlineClear } from "react-icons/ai";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "@clerk/clerk-react";
import { UserButton } from '@clerk/clerk-react';
import AceEditor from "react-ace";
import { CirclesWithBar } from 'react-loader-spinner';
import { faker } from '@faker-js/faker';
// import {MySelect} from './components/common/Select';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from "react-select";
import Validator from "../validator.d.js";
// import TextField from '@mui/material/TextField';
import Input from '@mui/base/Input';
import { useForm, Controller, useController, set } from "react-hook-form";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';
import { AppContext } from '../Context';
import { Tooltip as ReactTooltip } from 'react-tooltip'


import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"
import AppWorkspace from "./AppWorkspace.jsx"
import { useNavigate, NavLink, useParams } from "react-router-dom";
import NavBar from './NavBar.js'
import Builder from './Builder.jsx';
import Editor from './Editor.jsx';
import Versions from './Versions.jsx';
import AlertDialog from './AlertDialog.js';
// import { TextField,TextareaAutosize } from "@mui/material";

function Main({ workspace }) {
  const context = useContext(AppContext);
  const { state, setState, utils, setUtils } = context;
  const navigate = useNavigate();
  // state for storing the prompt and the completion
  const [prompt, setPrompt] = useState("");
  const [appAlias, setAppAlias] = useState("");
  const [completion, setCompletion] = useState("");
  const [code, setCode] = useState("");
  const [code2, setCode2] = useState("");
  const [loading, setLoading] = useState(false);
  const [projectAlertDialogOpen, setProjectAlertDialogOpen] = useState(false);
  const [appAlertDialogOpen, setAppAlertDialogOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);


  const { isLoaded, isSignedIn, user } = useUser();
  const { userId, sessionId, getToken } = useAuth();

  const [latestVersion, setLatestVersion] = useState("");

  const [projectName, setProjectName] = useState("");
  const [projectNameTmp, setProjectNameTmp] = useState("");
  const [appName, setAppName] = useState("");
  const [appNameTmp, setAppNameTmp] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);
  const [projectOption, setProjectOption] = useState(null);
  const [appOption, setAppOption] = useState(null);
  const [appOptions, setAppOptions] = useState([]);
  const [showProjectNameTooltip, setShowProjectNameTooltip] = useState(false);
  const backendApiBaseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL

  const [appTemplateOptions, setAppTemplateOptions] = useState([]);
  const [appTemplateOption, setAppTemplateOption] = useState(null);
  const [appTemplate, setAppTemplate] = useState("");

  // setState({...state, prompt: ""})
  console.log(JSON.stringify(state))

  // const [initState] = useState({projectName:"", errors:""});
  // const [state, setState] = useState(appContext);
  const useFormState = useForm({
    defaultValues: {
      projectName: state.projectName,
      appName: state.appName,
      prompt: state.prompt
    }
  });
  const { register, handleSubmit, watch, formState: { errors }, control, setValue } = useFormState;

  const handleCompletionClick = async (state) => {
    console.log("handleCompletionClick")
    scrollToSection('loading');
    state.loading = "Loading...";
    let app_version_dev = state.app_version_dev
    state.app_version_dev = "";
    if (state.appTemplate && state.appUserId) {
      state.code = ""
      state.completion = ""
      state.appUserId = ""
    }
    setState({ ...state });

    // make a request to the backend endpoint with the prompt as a query parameter
    let data = {
      projectName: state.projectName, appName: state.appName, prompt: state.prompt,
      user_id: userId, ai_message: state.code != '' ? state.code : state.completion,
      ai_chat_integration: state.hasAiChatIntegration, app_version: app_version_dev,
      email_sender_integration: state.hasEmailSenderIntegration,
    };
    console.log(data);
    axios.post(
      `${backendApiBaseUrl}/completion`,
      data,
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    ).then(response => {

      state.latestVersions = []
      for (let item of response.data) {
        state.latestVersions.push({ app_version: item[1], app_code: item[0] })
      }

      state.projectAppList = null;
      console.log(response.data);
      state.loading = "";
      setState({ ...state });
      navigate("/editor")
    }).catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 402) {
          state.hasProAccess = false
          state.showUpgradeModal = true
          state.upgradeMessage = error.response.data.detail
          setState({ ...state });
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
      state.loading = "";
      setState({ ...state });
    });
    // set the completion state with the response data

  };

  const handleSelectCompletion = async (data) => {
    setCompletion(data[0]);
    setCode(data[0]);
    state.code = data[0];
    state.completion = data[0];
    state.app_version_dev = data[1];
    setState({ ...state });
    // state['code'] = response.data
    populateProjectAppVersionList();
  }

  const handleProjectAppList = async (reset = false) => {
    console.log("handleProjectAppList")
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    let data = null
    if (!state.projectAppList || reset) {
      const response = await axios.post(
        `${backendApiBaseUrl}/project_app_list`,
        { projectName: projectName, appName: appName, user_id: userId },
        {
          headers: {
            'Authorization': 'Bearer ' + await getToken()
          }
        }
      );

      data = JSON.parse(response.data)
    } else {
      data = state.projectAppList
    }

    state.projectAppList = data
    projectOptions.length = 0;
    appOptions.length = 0;
    let projectOptionsSet = new Set()
    let appOptionsSet = new Set()
    for (let item of data) {
      projectOptionsSet.add(item[0])
      if (state.projectName == item[0]) {
        appOptionsSet.add(item[1])
      }
    }
    for (let item of projectOptionsSet) {
      projectOptions.push({ value: item, label: item })
    }
    for (let item of appOptionsSet) {
      appOptions.push({ value: item, label: item })
    }
    var curProjectOption = projectOptions.find(option => option.value === state.projectName)
    if (!curProjectOption && state.projectName) {
      curProjectOption = { value: state.projectName, label: state.projectName }
      projectOptions.push(curProjectOption)
    }
    setProjectOption(curProjectOption)
    if (!state.projectName) {
      setProjectOption(null)
    }    

    var curAppOption = appOptions.find(option => option.value === state.appName)
    if (!curAppOption && state.appName) {
      curAppOption = { value: state.appName, label: state.appName }
      appOptions.push(curAppOption)
    }
    setAppOption(curAppOption)
    if (!state.appName) {
      setAppOption(null)
    }
    console.log("setAppOption" + state.appName)

  };




  const getUserSettings = async () => {
    console.log("getUserSettings")
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    let data = null
    const response = await axios.get(
      `${backendApiBaseUrl}/user_settings/${userId}`,
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );

    console.log(JSON.stringify(response.data))
    // data = JSON.parse(response.data)
    state.hasProAccess = response.data['access_type'] == 'PRO'
    setState({ ...state })

  };

  const handleProjectNameChange = (e) => {
    console.log("handleProjectNameChange");
    // console.log("handleStateChange"+JSON.stringify(e.target))
    // Handle Validation on touch

    const { name, value } = e.target;
    if (!value) {
      setValue("projectName", "")
      setValue("appName", "")    
      setValue("prompt", "")      
      handleProjectAppList(true).then(() => {
      })
      setAppTemplateOption(appTemplateOptions[0])
      state.appUserId = null
      state.appTemplate = ""
    }
    state.projectName = value
    state.appName = "";
    state.app_version = "";
    state.app_version_dev = "";
    state.loading = "";
    state.appAlias = "";
    state.code = "";
    state.prompt = "";
    state.completion = "";
    state.hasAiChatIntegration = false
    state.latestVersions = []
    setState({ ...state });
    setProjectOption(projectOptions.find(option => option.value === value));

    setAppOption(undefined)
    setValue("projectName", state.projectName)
    console.log("handleProjectNameChange" + name + "-" + value + "-" + state.projectName);
  }

  const handleAppNameChange = (e) => {
    console.log("handleAppNameChange");
    // console.log("handleStateChange"+JSON.stringify(e.target))
    // Handle Validation on touch
    setState({ ...state });
    const { name, value } = e.target;
    // state[name] = value;    
    state.appName = value
    setValue("appName", state.appName)
    // setState({...state});
    if (state.projectAppList) {
      let currentApp = state.projectAppList.find(app => app[1] === state.appName);
      if (currentApp || state.appTemplate) {
        console.log("currentApp:" + state.appName);
        if (!state.appTemplate) {
          state.appAlias = currentApp[3];
          state.app_version = currentApp[2];
          state.app_version_dev = currentApp[2];
        } else {
          console.log(`currentApp using template: ${state.appTemplate}` + state.appName);
          state.appAlias = "";
          state.app_version = "";
          state.app_version_dev = "";
        }
        // setState({...state});
        populateProjectAppVersionList().then(() => {
          populateProjectAppByVersion();
        });

        // state.code= undefined
        // state.prompt= ""        

      } else {
        console.log("new App: " + state.appName);
        state.code = undefined;
        state.completion = undefined;
        state.prompt = undefined;
        state.app_version = undefined;
        state.appAlias = undefined;
        state.app_version_dev = undefined
        state.hasAiChatIntegration = false
        state.latestVersions = []
        setState({ ...state, prompt: "", code: "", completion: "" });
        navigate("/builder")
      }      
    }

    // if(!state.app_version && state.app_version!= ""){
    //   appOptions.push({value: value, label: value})
    // }

    // setState({...state, appName: state.appName, projectName: state.projectName, code: undefined, prompt: ""})
    setAppOption(appOptions.find(option => option.value === value));
    console.log("handleAppNameChange" + name + "-" + value + "-" + state.appName);
  }

  const handlePricingClick = () => {
    navigate("/pricing")
  }

  const handleCloseModal = () => {
    setState({ ...state, showUpgradeModal: false })
  };

  const populateProjectAppVersionList = async () => {
    console.log("populateProjectAppVersionList: " + state.appName)
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    const response = await axios.post(
      `${backendApiBaseUrl}/project_app_versions`,
      { projectName: state.projectName, appName: state.appName, user_id: state.appUserId ?? userId },
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );
    let data = JSON.parse(response.data);
    console.log(JSON.stringify(data));
    var latestVersion = null;
    setLatestVersion(null);
    for (let item of data) {
      if (!latestVersion) {
        latestVersion = item['app_version'];
      }
    }
    console.log(`setLatestVersion ${latestVersion}`)
    if (!state.app_version) {
      state.app_version = latestVersion;
      setState({ ...state });
    }
    if (!state.app_version_dev && !state.appTemplate) {
      state.app_version_dev = latestVersion;
      setState({ ...state });
    }
    setLatestVersion(latestVersion);
  };

  const populateProjectAppByVersion = async () => {
    console.log("populateProjectAppByVersion")
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    const response = await axios.post(
      `${backendApiBaseUrl}/project_app_by_version`,
      { projectName: state.projectName, appName: state.appName, user_id: state.appUserId ?? userId, app_version: state.app_version },
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );
    let data = JSON.parse(response.data);
    // console.log(JSON.stringify(data))

    state.code = data.response;
    state.completion = data.response;
    // console.log(JSON.stringify(versionCode))
    state.hasAiChatIntegration = data.response.includes("/ai-chat");
    state.prompt = data.prompt;
    setValue("prompt", state.prompt)

    if (state.appTemplate && state.appUserId) {
      state.app_version = "";
      state.appAlias = "";
      state.app_version_dev = ""
    }

    setState({ ...state });
  };
  const scrollRef = useRef([]);
  const scrollToSection = id => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
    // if (scrollRef.current.length) {
    //   scrollRef.current[id].scrollIntoView();
    // }
  };

  function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  const populateAppTemplateList = async () => {
    console.log("populateAppTemplateList")
    // state.appTemplate = ""
    // setAppTemplateOption(undefined)
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    const response = await axios.get(
      `${backendApiBaseUrl}/app_template_list_all`,
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );
    let data = JSON.parse(response.data);
    // console.log(JSON.stringify(data))
    // "project_name", "app_name", "app_version", "app_alias", "app_type", "created_by", "app_description"
    appTemplateOptions.length = 0;
    appTemplateOptions.push({ value: "", label: `${""}|${""}|\n${"Create A New App"}` })
    for (let item of data) {
      appTemplateOptions.push({ value: item.app_alias, label: `${item.project_name}|${item.app_name}|\n${item.app_description}|${item.created_by}|${item.app_alias}|${item.app_version}` })
    }
    if (!state.appTemplate) {
      let randomAppTemplate = data[randomInteger(0, data.length - 1)]
      handleAppTemplateChange({ target: { value: randomAppTemplate.app_alias, name: "appTemplate" } })
      console.log(`randomAppTemplate ${JSON.stringify(randomAppTemplate)}`)
    } else {
      handleAppTemplateChange({ target: { value: state.appTemplate, name: "appTemplate" } })
      console.log(`paramAppTemplate ${state.appTemplate}`)      
    }
    
  };

  const handleAppTemplateChange = (e) => {
    const { name, value } = e.target;
    let appTemplateOptionTmp = appTemplateOptions.find(option => option.value === value)
    setAppTemplateOption(appTemplateOptionTmp)
    state.appTemplate = value
    handleProjectAppList(true).then(() => {
      if (value) {
        state.appUserId = appTemplateOptionTmp.label.split('|')[3]
        // setState({...state})
        state.projectAppList.push([appTemplateOptionTmp.label.split('|')[0], appTemplateOptionTmp.label.split('|')[1],
        appTemplateOptionTmp.label.split('|')[5], appTemplateOptionTmp.label.split('|')[4]])
        handleProjectNameChange({ target: { value: appTemplateOptionTmp.label.split('|')[0], name: "projectName" } })
        handleAppNameChange({ target: { value: appTemplateOptionTmp.label.split('|')[1], name: "appName" } })
        setValue("projectName", state.projectName)
        setValue("appName", state.appName)
        setValue("prompt", state.prompt)
      } else {
        state.appUserId = null
        handleProjectNameChange({ target: { value: "", name: "projectName" } })
      }
    })
  }

  const handleShowNewApp = (state) => {
    console.log("handleShowNewApp")
    state.appTemplate = ""
    state.projectName = "";
    state.appName = "";
    state.code = undefined;
    state.completion = undefined;
    state.prompt = undefined;
    state.app_version = undefined;
    state.appAlias = undefined;
    state.app_version_dev = undefined
    state.hasAiChatIntegration = false
    state.latestVersions = []
    setState({ ...state, prompt: "", code: "", completion: "" });
    handleAppTemplateChange({ target: { value: "", name: "appTemplate" } })

  }

  useEffect(() => {
    if(!initialLoad) return;
    console.log("useEffect Main")
    handleProjectAppList().then(() => {
    })
    if (state.projectName && state.appName) {
      if (!state.appTemplate) {
        populateProjectAppVersionList();

        if (state.projectAppList) {
          let currentApp = state.projectAppList.find(app => app[1] === state.appName);
          if (currentApp && currentApp[3] && state.app_version) {
            setState({ ...state, appAlias: currentApp[3] });
          }
        }
      }
    }
    // console.log(`${state.projectName}-${state.appName}-${projectOptions.find(option => option.value === state.projectName)}-${JSON.stringify(projectOptions)}`)
  }, [state.projectName, state.appName, state.appAlias, state.loading]);

  // useEffect(() => {
  //   console.log(`useEffect handleAppTemplateChange ${state.appTemplate}`)
  //   handleAppTemplateChange({ target: { value: state.appTemplate, name: "appTemplate" } })
  //   // console.log(`${state.projectName}-${state.appName}-${projectOptions.find(option => option.value === state.projectName)}-${JSON.stringify(projectOptions)}`)
  // }, [state.appTemplate]);

  useEffect(() => {
    getUserSettings().then(() => {
      console.log(`Loaded user Settings!`)
      setInitialLoad(true)
    })
    utils.handleCompletionClick = handleCompletionClick
    setUtils({ ...utils })
    if (!state.projectName && !state.appName) {
      handleProjectAppList().then(() => {
        populateAppTemplateList().then(() => {
          console.log(`Loaded App Template List!`)
        })
        console.log(`${state.projectName}-${state.appName}-${projectOptions.find(option => option.value === state.projectName)}-${JSON.stringify(projectOptions)}`)
      })
    }

  }, [])


  if (!isLoaded || !isSignedIn) {
    return null;
  }


  return (
    <div className="bg-white max-w-full-x min-w-full-x md:w-fullx " id="mainContainer">
      {/* Header */}

      <input type={"hidden"} value={appAlias} />
      <header className="xcontainer mx-auto px-6x py-4x bg-gray-100">
        <NavBar hasProAccess={state.hasProAccess}></NavBar>
      </header>
      {/* Main */}
      <div className="flex flex-col items-center pt-10 md:pt-0 md:justify-center min-h-screen bg-white mt-4 px-4" style={{ marginBottom: "72px" }}>

        {state.showUpgradeModal && <UpgradeModal onClose={handleCloseModal} onUpgrade={handlePricingClick} />}
        <div className="md:w-144x flex items-start md:justify-center w-full grid grid-flow-col lg:auto-cols-max mb-4">
          <div className="w-full flex flex-col items-start items-centerx mb-2 space-y-1 md:space-y-0 md:space-x-4 md:flex-row mt-4 md:mt-0">


            <div className="w-full md:w-96">
              <div className="md:mx-4 mx-0 w-full max-w-full lg:w-96 flex flex-col items-start mb-2 md:mb-0">
                <h3 className="text-sm md:text-1xl font-bold mb-2">Choose An App Template</h3>
                <div className="w-full md:w-96 flex items-center mb-2">
                  <a data-tooltip-id="my-tooltip0" data-tooltip-delay-hide={1000} className="w-full md:w-96">

                    <Controller
                      render={({ field }) =>
                        <>

                          <Select {...field}
                            className="w-full md:w-96"
                            onChange={v => {
                              handleAppTemplateChange({ target: { value: v?.value, name: "appTemplate" } });
                              field.onChange(v?.value);
                            }
                            }
                            // required
                            formatCreateLabel={userInput => `Click or Enter to Create ${userInput}`}
                            placeholder='Select or Type'
                            options={appTemplateOptions}
                            formatOptionLabel={(option) => (<h3 className='text-purple-500'>{option?.label.split('|')[2]}</h3>)}
                            value={appTemplateOption}
                            tabIndex="1"
                          // {...register("projectName", { required: true })} aria-invalid={errors.projectName ? "true" : "false"}
                          />
                        </>
                      }
                      id="appTemplate"
                      name="appTemplate"
                      // {...register("projectName", { required: true })} aria-invalid={errors.projectName ? "true" : "false"}
                      control={control}
                      rules={{ required: false }}

                    />
                  </a>
                  <ReactTooltip id="my-tooltip0"
                    isOpen={!!errors.appTemplate} place={"bottom-start"}
                    offset={6}
                    style={{ padding: 0, marginLeft: 10 }}
                  >
                    <div className='h-4 m-1'>
                      {errors.appTemplate && <p className='text-red-700 text-xs'>First, select an app template.</p>}
                    </div>
                  </ReactTooltip>
                </div>
              </div>

            </div>


            <div className="w-full md:w-48">
              <div className="md:mx-4 mx-0 w-full max-w-full lg:w-48 flex flex-col items-start mb-2 md:mb-0">
                <h3 className="text-sm md:text-1xl font-bold mb-2">Project</h3>
                <div className="w-full md:w-48 flex items-center mb-2">
                  {/* <CreatableSelect
            isClearable
            required
            id="projectName"
            name="projectName"
            value={projectOptions.find(option => option.value === projectName)}
            // onInputChange={v=>handleStateChange({ target: { value: v, name: "projectName" }})}
            onChange={v=>handleStateChange({ target: { value: v?.value, name: "projectName" }})}
            options={projectOptions}
            className="w-full md:w-48"
            
            {...register("projectName", { required: true })} aria-invalid={errors.projectName ? "true" : "false"}
            // components={{
            //   Input
            // }}                   
          /> */}
                  <AlertDialog open={projectAlertDialogOpen} setOpen={setProjectAlertDialogOpen}
                    dialogTitle={"Select Project Name"}
                    dialogDescription={`Do you want to use "${projectNameTmp}" as your Project Name?`}
                    agreeText={"Yes"} agreeFunction={() => {
                      handleProjectNameChange({ target: { value: projectNameTmp, name: "projectName" } });
                      setProjectNameTmp("");
                    }}
                    disagreeText={"No"} disagreeFunction={() => setProjectNameTmp("")}
                  ></AlertDialog>
                  <a data-tooltip-id="my-tooltip" data-tooltip-delay-hide={1000} className="w-full md:w-48">

                    <Controller
                      render={({ field }) =>
                        <>

                          <CreatableSelect {...field}
                            className="w-full md:w-48"
                            onChange={v => {
                              handleProjectNameChange({ target: { value: v?.value, name: "projectName" } });
                              field.onChange(v?.value);
                              setProjectOption(projectOptions.find(option => option.value === v?.value));
                              console.log(JSON.stringify(projectOption))
                            }
                            }
                            onBlur={(v) => {
                              if (v.target.value) {
                                console.log(`Want to discard the project name? ${v.target.value}`)
                                setProjectNameTmp(v.target.value)
                                setProjectAlertDialogOpen(true)
                              }
                            }}
                            isClearable
                            // required
                            formatCreateLabel={userInput => `Click or Enter to Create ${userInput}`}
                            placeholder='Select or Type'
                            options={projectOptions}
                            value={projectOption}
                            tabIndex="1"
                          // {...register("projectName", { required: true })} aria-invalid={errors.projectName ? "true" : "false"}
                          />
                        </>
                      }
                      id="projectName"
                      name="projectName"
                      // {...register("projectName", { required: true })} aria-invalid={errors.projectName ? "true" : "false"}
                      control={control}
                      rules={{ required: true }}

                    />
                  </a>
                  <ReactTooltip id="my-tooltip"
                    isOpen={!!errors.projectName} place={"bottom-start"}
                    offset={6}
                    style={{ padding: 0, marginLeft: 10 }}
                  >
                    <div className='h-4 m-1'>
                      {errors.projectName && <p className='text-red-700 text-xs'>First, give this project a name.</p>}
                    </div>
                  </ReactTooltip>
                </div>
              </div>

            </div>
            <div className="w-full md:w-48">
              <div className="md:mx-4 mx-0 w-full max-w-full flex flex-col items-start mb-2 md:mb-0">
                <div className="w-full flex flex-row items-center">
                  <h3 className="text-sm md:text-1xl font-bold mb-2">App
                  </h3>
                  {state.app_version &&
                    <div className="w-fullx md:w-48x flex items-center h-full">
                      <div className="mx-4 max-w-fullx flex flex-col items-start md:mb-4x mb-0">
                        <div className="w-full md:w-48x flex items-center mb-2">
                          <span className="inline-block align-middle mx-4 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">{state.app_version}</span>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <div className="w-full md:w-48 flex items-center mb-2">
                  <AlertDialog open={appAlertDialogOpen} setOpen={setAppAlertDialogOpen}
                    dialogTitle={"Select App Name"}
                    dialogDescription={`Do you want to use "${appNameTmp}" as your App Name?`}
                    agreeText={"Yes"} agreeFunction={() => {
                      handleAppNameChange({ target: { value: appNameTmp, name: "appName" } });
                      setAppNameTmp("");
                    }}
                    disagreeText={"No"} disagreeFunction={() => setAppNameTmp("")}
                  ></AlertDialog>
                  <a data-tooltip-id="my-tooltip2" data-tooltip-delay-hide={1000} className="w-full md:w-48">
                    <Controller
                      render={({ field }) =>
                        <CreatableSelect {...field}
                          // required
                          formatCreateLabel={userInput => `Click or Enter to Create ${userInput}`}
                          placeholder='Select or Type'
                          value={appOption}
                          onChange={v => {
                            handleAppNameChange({ target: { value: v?.value, name: "appName" } });
                            field.onChange(v?.value)
                          }}
                          onBlur={(v) => {
                            if (v.target.value) {
                              console.log(`Want to discard the app name? ${v.target.value}`)
                              setAppNameTmp(v.target.value)
                              setAppAlertDialogOpen(true)
                            }
                          }}
                          options={appOptions}
                          className="w-full md:w-48"
                          tabIndex="2"
                          formatOptionLabel={(option) => (
                            <div className="w-full flex flex-row items-center">
                              <div>
                                <h3 className='text-purple-500'>
                                  {option?.label}
                                </h3>
                              </div>

                            </div>
                          )}
                        // {...register("appName", { required: true })} aria-invalid={errors.appName ? "true" : "false"}
                        />}

                      control={control}
                      rules={{ required: true }}
                      id="appName"
                      name="appName"

                    />
                  </a>
                  <ReactTooltip id="my-tooltip2"
                    isOpen={!!errors.appName} place={"bottom-start"}
                    offset={6}
                    style={{ padding: 0, marginLeft: 10 }}
                  >
                    <div className='h-4 m-1'>
                      {errors.appName && <p className='text-red-700 text-xs'>Now, enter a name for your App.</p>}
                    </div>
                  </ReactTooltip>
                </div>

              </div>
            </div>
          </div>
        </div>


        <AppWorkspace />
        <CirclesWithBar
          id="loading"
          className="my-5"
          height="100"
          width="100"
          color="#4B0082"
          wrapperStyle={{}}
          wrapperClass=""
          visible={state.loading !== undefined && state.loading != ''}
          outerCircleColor=""
          innerCircleColor=""
          barColor=""
          ariaLabel='circles-with-bar-loading'
        />
        <div className="text-3xl mb-1">{state.loading}</div>

        <div className="flex flex-col items-center mb-4 md:flex-row md:justify-center px-4 w-full">

          {workspace == 'builder' &&
            <Builder useFormState={useFormState} context={context} handleShowNewApp={handleShowNewApp}></Builder>
          }
          {workspace == 'editor' &&
            <Editor context={context}></Editor>
          }
          {workspace == 'versions' &&
            <Versions context={context}></Versions>
          }

        </div>
      </div>
    </div>
  );
}

export default Main;

const UpgradeModal = ({ onClose, onUpgrade }) => {
  const { state, setState } = useContext(AppContext);
  return (
    <div className="fixed inset-0 flex items-center justify-center z-10">
      <div className="bg-white rounded-lg p-6 border-solid border-2 border-indigo-600">
        <h2 className="text-2xl font-bold mb-4">Upgrade to Pro</h2>
        <p className="text-gray-600 mb-6">{state.upgradeMessage}<br></br>Please, upgrade to the PRO version! Unlock many other premium features.</p>
        <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mr-2 rounded" onClick={onClose}>Close</button>
        <button className="bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded" onClick={onUpgrade}>Upgrade to Pro</button>
      </div>
    </div>
  );
};
