import {
    ClerkProvider,
    SignedIn,
    SignedOut,
    RedirectToSignIn,
    SignIn,
    SignUp,
    UserButton,
  } from "@clerk/clerk-react";
import { BrowserRouter, Route, Routes, useNavigate, useParams } from "react-router-dom";
import Main from './pages/Main';

import { createContext, useState, useEffect, useContext } from "react";

import { AppContext } from './Context';
import Pricing from "./pages/Pricing";
// import { useUser, useClerk } from "@clerk/clerk-react";
// import { Field } from "v4f";

// import { useState, useContext, useEffect } from "react";


if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
throw new Error("Missing Publishable Key")
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
  
function SignupWithParam() {
    let { appTemplateParam } = useParams()
    const context = useContext(AppContext);
    const { state, setState, utils, setUtils } = context;
    setState({...state, appTemplate: appTemplateParam})    
    console.log(`/builder/:${appTemplateParam}`)
    return (
        <SignUp routing="path" path={`/sign-up-template/${appTemplateParam}`}
        redirectUrl={`/builder`} 
        afterSignUpUrl={`/builder`} 
        afterSignInUrl={`/builder`}/>
    )    
}

function ClerkProviderWithRoutes() {
const navigate = useNavigate();
  
return (
    <ClerkProvider
    redirectUrl={"/builder"}
    publishableKey={clerkPubKey}
    navigate={(to) => navigate(to)}
    >
    <Routes>
        <Route path="/" 
        element={
        <>
            <SignedIn>
                <Main workspace="builder" />
            </SignedIn>
            <SignedOut>
            <RedirectToSignIn />
            </SignedOut>
        </>
        }
        />
        <Route
        path="/sign-in/*"
        element={<SignIn routing="path" path="/sign-in" redirectUrl={"/builder"} afterSignUpUrl={"/builder"} afterSignInUrl={"/builder"}/>}
        />
        <Route
        path="/sign-up/*"
        element={<SignUp routing="path" path="/sign-up" redirectUrl={"/builder"} afterSignUpUrl={"/builder"} afterSignInUrl={"/builder"}/>}
        />
        <Route
        path="/sign-up-template/:appTemplateParam/*"
        element={<SignupWithParam></SignupWithParam>}
        />        
        <Route
        path="/builder"
        element={
        <>
            <SignedIn>
                <Main workspace="builder" />
            </SignedIn>
            <SignedOut>
            <RedirectToSignIn />
            </SignedOut>
        </>
        }
        />        
        <Route
        path="/editor"
        element={
        <>
            <SignedIn>
                <Main workspace="editor" />
            </SignedIn>
            <SignedOut>
            <RedirectToSignIn />
            </SignedOut>
        </>
        }
        />        
        <Route
        path="/versions"
        element={
        <>
            <SignedIn>
                <Main workspace="versions" />
            </SignedIn>
            <SignedOut>
            <RedirectToSignIn />
            </SignedOut>
        </>
        }
        />  
        <Route
        path="/pricing"
        element={
        <>
            <SignedIn>
            <Pricing />
            </SignedIn>
            <SignedOut>
            <RedirectToSignIn />
            </SignedOut>
        </>
        }
        />               
    </Routes>
    </ClerkProvider>
);
}

function App() {
    let initialState = {}
    initialState.projectName = ""
    initialState.appName = "";
    initialState.app_version = "";
    initialState.app_version_dev = "";
    initialState.appUserId = "";
    initialState.loading = "";
    initialState.appAlias = "";
    initialState.code = "";
    initialState.prompt = "";
    initialState.completion = "";
    initialState.hasAiChatIntegration = false  
    initialState.hasEmailSenderIntegration = false  
    initialState.hasProAccess = false 
    initialState.showUpgradeModal = false 
    initialState.showImprovePrompt = false 
    initialState.upgradeMessage = ""  
    initialState.appTemplate = ""
    const [state, setState] = useState(initialState);  
    let utilsInitialState = {}  
    utilsInitialState.handleCompletionClick = () => {}
    const [utils, setUtils] = useState(utilsInitialState);   
return (
    <BrowserRouter>
    <AppContext.Provider value={{state, setState, utils, setUtils}}>
    <ClerkProviderWithRoutes />
    </AppContext.Provider>
    </BrowserRouter>
);
}

export default App;