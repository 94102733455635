// import React, { useState, useEffect } from "react";

// const ProductDisplay = () => (
//   <section>
//     <div className="product">
//       <img
//         src="https://i.imgur.com/EHyR2nP.png"
//         alt="SaaS Web App Generator subscription"
//       />
//       <div className="description">
//       <h3>SaaS Web App Generator subscription</h3>
//       <h5>$29.00</h5>
//       </div>
//     </div>
//     <form action="/create-checkout-session" method="POST">
//       <button type="submit">
//         Checkout
//       </button>
//     </form>
//   </section>
// );

// const Message = ({ message }) => (
//   <section>
//     <p>{message}</p>
//   </section>
// );

// export default function Pricing() {
//   const [message, setMessage] = useState("");

//   useEffect(() => {
//     // Check to see if this is a redirect back from Checkout
//     const query = new URLSearchParams(window.location.search);

//     if (query.get("success")) {
//       setMessage("Order placed! You will receive an email confirmation.");
//     }

//     if (query.get("canceled")) {
//       setMessage(
//         "Order canceled -- continue to shop around and checkout when you're ready."
//       );
//     }
//   }, []);

//   return message ? (
//     <Message message={message} />
//   ) : (
//     <ProductDisplay />
//   );
// }
import * as React from 'react';
import { useAuth } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";

function Pricing() {
  const { userId, sessionId, getToken } = useAuth();
  const { isLoaded, isSignedIn, user } = useUser();
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <>
{/* <stripe-pricing-table pricing-table-id="prctbl_1NHfIFADR3vExwv7s0vk5F4z"
publishable-key="pk_test_51NHcMMADR3vExwv7aSsEW26R5Qd4UdGRzvL4ro0XOdDvxVvosybirJa11ZO5gWAMuyPsAGqOgSTFhOwJkvwsIFMn00og2pmmdM"

client-reference-id={userId}
customer-email={user.emailAddresses}
>
</stripe-pricing-table> */}
<stripe-pricing-table pricing-table-id="prctbl_1O1GbHADR3vExwv71nFeodKo"
publishable-key="pk_live_51NHcMMADR3vExwv7ErEzcnaRzhSJnn7JL5pIsZJHcYzoGdEE751IngFRK8jtWahyyDLgA3KHH60mmERqXeynQQEG000WJv9ZBP">
client-reference-id={userId}
customer-email={user.emailAddresses}
</stripe-pricing-table>
</>
  )
}

export default Pricing;