import * as React from 'react';
import { useState, useContext, useEffect } from "react";
import { FaCopy } from "react-icons/fa";
import { AiOutlineClear } from "react-icons/ai";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "@clerk/clerk-react";
import { UserButton } from '@clerk/clerk-react';
import AceEditor from "react-ace";
import { CirclesWithBar } from 'react-loader-spinner';
import { faker } from '@faker-js/faker';
// import {MySelect} from './components/common/Select';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from "react-select";
import Validator from "../validator.d.js";
// import TextField from '@mui/material/TextField';
import Input from '@mui/base/Input';
import { useForm, Controller, useController } from "react-hook-form";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGrid , GridRowsProp, GridColDef} from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';
import { AppContext } from '../Context';


import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"
// import { TextField,TextareaAutosize } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Versions({context}) {
  // state for storing the prompt and the completion
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState("");
  const [appAlias, setAppAlias] = useState("");
  const [completion, setCompletion] = useState("");
  
  const [loading, setLoading] = useState(false);


  const { isLoaded, isSignedIn, user } = useUser();
  const { userId, sessionId, getToken } = useAuth();

  const [projectName, setProjectName] = useState("");
  const [appName, setAppName] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);
  const [projectOption, setProjectOption] = useState(null);
  const [appOptions, setAppOptions] = useState([]);
  const backendApiBaseUrl=process.env.REACT_APP_BACKEND_API_BASE_URL
  const { state, setState, utils, setUtils } = context;
  const [versionCode, setVersionCode] = useState("");

  // console.log(JSON.stringify(state))

  // const [initState] = useState({projectName:"", errors:""});
  // const [state, setState] = useState(appContext);
  const { register, handleSubmit, watch, formState: { errors }, control } = useForm({defaultValues: {
    projectName: state.projectName,
    appName: state.appName
  }});
  // const {control} = useForm();
  // const { field: select } = useController({ name: 'projectName' })

  const handleProjectChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== "custom") {
      setProjectName(selectedOption.value);
    }
  };

  const handleAppChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== "custom") {
      setAppName(selectedOption.value);
    }
  };  
  
  // window.alert(sessionId)
  // function to handle input change
  const handleChange = (e) => {
    setPrompt(e.target.value);
  };


  const handleProjectAppList = async () => {
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    const response = await axios.post(
      `${backendApiBaseUrl}/project_app_list`,
      { projectName: projectName, appName: appName, user_id: userId},
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );
    let data = JSON.parse(response.data)
    // let projectOptionsTmp = []
    // let appOptionsTmp = []
    projectOptions.length = 0;
    appOptions.length = 0;
    for (let item of data) {
      projectOptions.push({value: item[0], label: item[0]})
      appOptions.push({value: item[1], label: item[1]})
    }
    setProjectOption(projectOptions.find(option => option.value === state.projectName))    
    //setProjectOptions(projectOptionsTmp)
    //setAppOptions(appOptionsTmp)
    
  };


  const handlePublishClick = async () => {
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    const response = await axios.post(
      `${backendApiBaseUrl}/save_project_app`,
      { projectName: projectName, appName: appName, user_id: userId},
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );
    // let data = JSON.parse(response.data)
    // for (let item of data) {
    //   projectOptions.push({value: item[0], label: item[0]})
    //   appOptions.push({value: item[1], label: item[1]})
    // }    
    
  };  

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(code);
  // };

  // const handleClear = () => {
  //   setCompletion("");
  //   setCode("");
  // };

  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const [rows, setRows] = useState([])
  
  const columns = [
    { field: 'requested_at', headerName: 'Created At', width: 150 },
    { field: 'prompt', headerName: 'Prompt', width: 150 },
    { field: 'app_version', headerName: 'App Version', width: 150 },
  ];

  const populateProjectAppVersionList = async () => {
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    const response = await axios.post(
      `${backendApiBaseUrl}/project_app_versions`,
      { projectName: state.projectName, appName: state.appName, user_id: userId},
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );
    let data = JSON.parse(response.data)
    console.log(JSON.stringify(data))
    let rowsTmp = []
    for (let item of data) {
      rowsTmp.push({id: item['app_version'], requested_at: item['requested_at'], prompt: item['prompt'], app_version: item['app_version']})
    }
    setRows(rowsTmp)    
    
  };  



  const handleDirty = (e) => {
    // Handle Validation on touch

    const { name, value } = e.target;

    const isValid = Validator[name].validate(value, {
      verbose: true,
      values: state
    });

    if (isValid !== true) {
      // setState({
      //   errors: { ...state.errors, [name]: isValid }
      // });
      state["errors"] = { ...state.errors, [name]: isValid };
    } else {
      // setState({
      //   errors: { ...state.errors, [name]: undefined }
      // });
      state["errors"] = { ...state.errors, [name]: undefined };
    }
  } 

  const handleStateChange = (e) => {
    console.log("handleStateChange");
    // console.log("handleStateChange"+JSON.stringify(e.target))
    // Handle Validation on touch

    const { name, value } = e.target;
    state[name] = value;
    console.log("handleStateChange"+name+"-"+value);
  }
  // const Input = props => <components.Input {...props} required={props.selectProps.required} />
  
  const populateProjectAppByVersion = async () => {
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    const response = await axios.post(
      `${backendApiBaseUrl}/project_app_by_version`,
      { projectName: state.projectName, appName: state.appName, user_id: userId, app_version: state.app_version_dev},
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );
    let data = JSON.parse(response.data)
    // console.log(JSON.stringify(data))
      
    setVersionCode(data.response)
    // console.log(JSON.stringify(versionCode))
    state.hasAiChatIntegration=data.response.includes("/ai-chat")
    state.prompt=data.prompt
    state.code=data.response  
    setState({...state})
  };  

  const handleRowClick = (params) => {

    state.app_version_dev = params.row.app_version
    setState({...state});
    
    console.log(`App "${params.row.app_version}-${state.app_version_dev}" clicked`);
    populateProjectAppByVersion();
  };

  const handleEditVersion = () => {
    if(state.app_version_dev){
      state['code'] = versionCode
      setState({...state})
      navigate("/builder");
    }
  }

  useEffect(() => {
    setRows([])
    if (state.projectName && state.appName){
      populateProjectAppVersionList();
    }
    console.log(`${state.projectName}-${state.appName}-${projectOptions.find(option => option.value === state.projectName)}-${JSON.stringify(projectOptions)}`)
  }, [state.projectName, state.appName]);

  if (!isLoaded || !isSignedIn) {
    return null;
  }


  return (
    <>
      
        <div className={isFullscreen ? 'hidden' : 'w-full md:w-2/6 md:mr-4'}>
        {/* <div className={isFullscreen ? 'hidden' : 'flex flex-col items-center md:flex-col mr-4'}>
            <button
              onClick={handleCopy}
              className="mt-4 py-2 px-4 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:bg-blue-600 flex items-center md:mt-0 md:w-full md:mb-2"
            >
              <FaCopy className="mr-2" />
              Copy
            </button>
            <button
              onClick={handleClear}
              className="mt-4 py-2 px-4 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:bg-blue-600 flex items-center md:mt-0 md:w-full"
            >
              <AiOutlineClear className="mr-2" />
              Apply
            </button>
          </div>     */}
          <Box sx={{ width: '100%' }} className="w-full mx-0 px-0">
      <Stack direction="row" spacing={0} sx={{ mb: 1 }} className='w-full'>
        {/* <Button size="small" onClick={handleRowClick}>
        <FaCopy className="mr-2" />
          Copy
        </Button> */}
        <Button size="small" onClick={handleEditVersion}
        disabled={!versionCode || state.loading}
        className={`w-full sm:w-3/6 disabled:opacity-50 right-0 bottom-5 mt-4 mr-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800`}
        >
        <FaCopy className="mr-2 " />
          Send to Builder
        </Button>
      </Stack>
      <DataGrid className='w-80 sm:w-full'
  initialState={{
    pagination: { paginationModel: { pageSize: 5 } },
  }} autoHeight  rowCount={20} rows={rows} columns={columns} onRowClick={handleRowClick}/>
    </Box>      

          </div>
          <div>
          {/* <button onClick={handlePublishClick}
            className="w-full md:w-auto ml-0 md:ml-4 py-2 px-4 rounded-lg bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 focus:from-blue-600 focus:to-blue-700 text-white shadow-md">
            Publish
          </button>           */}
          <div className="relative w-full mb-10 sm:mb-1">
            <button
            disabled={!versionCode || state.loading}
            onClick={toggleFullscreen}
            className={`disabled:opacity-50 ${isFullscreen ? 'fixed bottom-20 sm:bottom-5 right-0' : ' absolute right-0 bottom-5'} mt-4 mr-4 py-2 px-4 rounded-lg bg-indigo-700 text-white hover:bg-indigo-800 focus:outline-none focus:bg-indigo-800`}
          >
            {isFullscreen ? 'Close' : 'Preview'}
          </button>            
            <iframe
          srcDoc={versionCode}
          title="Rendered HTML"
          className={`max-w-full min-w-full w-full border-2 border-gray-300 ${isFullscreen ? 'w-screen h-screen' : 'w-96 h-[60vh] mt-4 border-2 border-gray-300 rounded-md shadow-md rounded-3xl'}`}
          style={{
            transformOrigin: '0 0',
            overflowX: 'hidden',
          }}
        />
          </div>
        </div>          
          <div className={isFullscreen ? 'hidden' : 'w-full md:w-3/6 md:mr-4 sm:ml-4'}>
            <AceEditor
              value={versionCode}
              mode="javascript"
              theme="github"
              name="UNIQUE_ID_OF_DIV"
              onChange={(value) => setVersionCode(value)}
              editorProps={{ $blockScrolling: true }}
              lineNumbers={true}
              setOptions={{ useWorker: false }}
              readOnly={true}
              width='300px'
              height='300px'
              className='w-full'
              // className={`${isFullscreenCode ? 'w-screen h-[90vh] w-fullx max-w-full w-full min-w-full max-h-full min-h-fullx h-fullx mb-5 mr-10x overflow-hidden' : 'border-2 border-gray-300 w-24 max-w-full min-w-full h-[40vh] md:h-[60vh] mt-1 border-2 border-gray-300 rounded-md shadow-md rounded-3xl'}`}
            />
          </div>

    </>
  );
}

export default Versions;
