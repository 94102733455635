import { Fragment, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { MdVerifiedUser } from "react-icons/md";

import UserProfilePage from './UserProfile'
import { UserProfile } from "@clerk/clerk-react";
import { SignOutButton } from "@clerk/clerk-react";
import { useNavigate, NavLink } from "react-router-dom";
import { UserButton } from '@clerk/clerk-react';
import { useUser } from "@clerk/clerk-react";
import Paper from '@mui/material/Paper';

const navigation = [
  { name: 'Pricing', href: '/pricing', current: false },
  { name: 'Discord', href: 'https://discord.gg/UaXJeJMwN', current: false }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar({hasProAccess}) {
    const [toggleUserProfileModal, setToggleUserProfileModal] = useState(false);
    const navigate = useNavigate();
    const { isLoaded, isSignedIn, user } = useUser();
    console.log(user)
  return (   
    <Paper className='z-50 fixed top-0 left-0 right-0 sm:z-0 sm:static' elevation={3}>
    <div>
      {toggleUserProfileModal && 
      <UserProfileModal onClose={()=>setToggleUserProfileModal(false)} hasProAccess={hasProAccess}></UserProfileModal>
      }        
    
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src="https://fireaiapp.com/OIG.png"
                    alt="Fire AI App"
                  /> 
                  <div className='ml-2 mr-4 text-white'>App Generator</div>              
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>              
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* <button
                  type="button"
                  className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={user.profileImageUrl}
                        alt=""
                      />
                      {hasProAccess &&                       
                      <MdVerifiedUser aria-label='PRO User' className='text-green-500'></MdVerifiedUser>
                      }
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={()=>setToggleUserProfileModal(!toggleUserProfileModal)}
                            className={classNames(active ? 'bg-gray-100' : '', 'w-full block px-4 py-2 text-sm text-gray-700')}
                          >
                            Your Profile
                          </button>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item> */}
                      <Menu.Item>
                        {({ active }) => (
                            <SignOutButton 
                            className={classNames(active ? 'bg-gray-100' : '', 'w-full block px-4 py-2 text-sm text-gray-700')}
                            signOutCallback={()=>navigate("/")}></SignOutButton>
                          
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    </div>
    </Paper>
  )
}
const UserProfileModal = ({onClose, hasProAccess}) => {
    // const { state, setState } = useContext(AppContext);
    return (
      <div className="fixed inset-0 flex items-center justify-center z-10">
        <div className="bg-white rounded-lg p-6 border-solid border-2 border-indigo-600 h-3/4 relative overflow-y-auto">
            <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mr-2 rounded" onClick={onClose}>Close</button>
          {/* <h2 className="text-2xl font-bold mb-4">Upgrade to Pro</h2>
          <p className="text-gray-600 mb-6">{state.upgradeMessage}<br></br>Please, upgrade to the PRO version! Unlock many other premium features.</p>
          <button className="bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded" onClick={onUpgrade}>Upgrade to Pro</button> */}
          <div className='flex flex-row mt-4 ml-20'>
          <p className="text-gray-600 mb-6">{`You are a ${hasProAccess?'PRO':'FREE'} User`}
          </p>
          {hasProAccess &&                       
                      <MdVerifiedUser aria-label='PRO User' className='text-green-500'></MdVerifiedUser>
                      }        
                      </div>      
      
          <UserProfile></UserProfile>   
        </div>
      </div>
    );
  };