import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HistoryIcon from '@mui/icons-material/History';
import { FaLaptopCode } from "react-icons/fa";

import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Route, BrowserRouter, Switch, Link, useNavigate, useLocation } from "react-router-dom";


export default function AppWorkspace() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue)

  };

  return (
    <Paper elevation={3}  className='grid justify-items-center z-50 fixed bottom-0 left-0 right-0 sm:z-0 sm:static'>
    <Tabs className='text-xs text-red' value={
      location.pathname !== "/"
        ? location.pathname
        : false
    } onChange={handleChange} aria-label="icon label tabs example" id="workspace"
    TabIndicatorProps={{
      style: {
        backgroundColor: "#D97D54"
      }
    }}
    >
      <Tab className='text-xs text-white' icon={<EngineeringIcon/>} label={<span className="text-xs">BUILDER</span>} value={"/builder"} component={Link} to={"/builder"}/>
      <Tab className='text-xs text-white' icon={<FaLaptopCode className="text-2xl"/>} label={<span className="text-xs">EDITOR</span>} value={"/editor"} component={Link} to={"/editor"}/>
      <Tab icon={<HistoryIcon />} label={<span className="text-xs">VERSIONS</span>}  value={"/versions"}  component={Link} to={"/versions"}/>
      {/* <Tab icon={<FavoriteIcon />} label="FAVORITES"  value={"/favorites"}  component={Link} to={"/favorites"} disabled/>
      <Tab icon={<SettingsApplicationsIcon />} label="SETTINGS"  value={"/settings"}  component={Link} to={"/settings"}  disabled/>
      <Tab icon={<ShoppingCartIcon />} label="SHOP"  value={"/shop"}  component={Link} to={"/shop"}  disabled/> */}
    </Tabs>
    </Paper>
  );
}