import * as React from 'react';
import { createContext, useState, useContext, useEffect } from "react";
import { FaCopy } from "react-icons/fa";
import { AiOutlineClear } from "react-icons/ai";
import { MdOutlineAutorenew, MdPublish } from "react-icons/md";
import { BiMessageRoundedEdit } from "react-icons/bi";
import { RiSave3Fill, RiShareForwardLine } from "react-icons/ri";


import { TbPrompt } from "react-icons/tb";

import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "@clerk/clerk-react";
import { UserButton } from '@clerk/clerk-react';
import AceEditor from "react-ace";
import { CirclesWithBar } from 'react-loader-spinner';
import { faker } from '@faker-js/faker';
// import {MySelect} from './components/common/Select';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from "react-select";
import Validator from "../validator.d.js";
// import TextField from '@mui/material/TextField';
import Input from '@mui/base/Input';
import { useForm, Controller, useController } from "react-hook-form";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'



import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"

// import { TextField,TextareaAutosize } from "@mui/material";

function Builder({ useFormState, context, handleShowNewApp}) {
  // state for storing the prompt and the completion
  const { state, setState, utils, setUtils } = context;
  const navigate = useNavigate();
  // const [prompt, setPrompt] = useState("");
  const [appAlias, setAppAlias] = useState("");
  const [completion, setCompletion] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("Refreshing Builder...")
  // console.log(`${state.projectName}-${state.appName}`)

  const { isLoaded, isSignedIn, user } = useUser();
  const { userId, sessionId, getToken } = useAuth();

  const [projectName, setProjectName] = useState("");
  const [appName, setAppName] = useState("");
  const projectOptions = [];
  const appOptions = [];
  const backendApiBaseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL
  // const [initState] = useState({projectName:"",errors:""});
  // const [state, setState] = useState(stateContext);
  // const ReferenceDataContext = createContext({ state, projectName, appName});

  const { register, handleSubmit, watch, formState: { errors }, control } = useFormState;
  // const {control} = useForm();
  // const { field: select } = useController({ name: 'projectName' })

  // const handleProjectChange = (selectedOption) => {
  //   if (selectedOption && selectedOption.value !== "custom") {
  //     setProjectName(selectedOption.value);
  //   }
  // };

  // const handleAppChange = (selectedOption) => {
  //   if (selectedOption && selectedOption.value !== "custom") {
  //     setAppName(selectedOption.value);
  //   }
  // };  

  // window.alert(sessionId)
  // function to handle input change
  // const handleChange = (e) => {
  //   setPrompt(e.target.value);
  // };

  // function to handle button click
  // const handleCompletionClick = async () => {
  //   setLoading('Loading...')
  //   // make a request to the backend endpoint with the prompt as a query parameter
  //   const response = await axios.post(
  //     `${backendApiBaseUrl}/completion`,
  //     { projectName: projectName, appName: appName, prompt: prompt, user_id: userId, ai_message: code != '' ? code : completion },
  //     {
  //       headers: {
  //         'Authorization': 'Bearer ' + await getToken()
  //       }
  //     }
  //   );
  //   // set the completion state with the response data
  //   setCompletion(response.data);
  //   setCode(response.data);
  //   setLoading('')
  // };
  // const handleBoostClick = async () => {
  //   // setCompletion('Loading...')
  //   // make a request to the backend endpoint with the prompt as a query parameter
  //   const response = await axios.post(
  //     `${backendApiBaseUrl}/prompt-boost`,
  //     { projectName: projectName, appName: appName, prompt: prompt, user_id: userId, ai_message: code != '' ? code : completion },
  //     {
  //       headers: {
  //         'Authorization': 'Bearer ' + await getToken()
  //       }
  //     }
  //   );
  //   // set the completion state with the response data
  //   if (window.confirm("Do you want a use this prompt:" + response.data) == true) {
  //     setPrompt(response.data)
  //   }
  // };

  // const handleProjectAppList = async () => {
  //   // setCompletion('Loading...')
  //   // make a request to the backend endpoint with the prompt as a query parameter
  //   const response = await axios.post(
  //     `${backendApiBaseUrl}/project_app_list`,
  //     { projectName: projectName, appName: appName, user_id: userId},
  //     {
  //       headers: {
  //         'Authorization': 'Bearer ' + await getToken()
  //       }
  //     }
  //   );
  //   let data = JSON.parse(response.data)
  //   for (let item of data) {
  //     projectOptions.push({value: item[0], label: item[0]})
  //     appOptions.push({value: item[1], label: item[1]})
  //   }    

  // };

  // const populateProjectAppVersionList = async () => {
  //   // setCompletion('Loading...')
  //   // make a request to the backend endpoint with the prompt as a query parameter
  //   const response = await axios.post(
  //     `${backendApiBaseUrl}/project_app_versions`,
  //     { projectName: state.projectName, appName: state.appName, user_id: userId},
  //     {
  //       headers: {
  //         'Authorization': 'Bearer ' + await getToken()
  //       }
  //     }
  //   );
  //   let data = JSON.parse(response.data)
  //   console.log(JSON.stringify(data))

  //   for (let item of data) {
  //     setState({...state, app_version: item['app_version']})
  //   }  

  // };    

  const scrollToSection = id => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
    // if (scrollRef.current.length) {
    //   scrollRef.current[id].scrollIntoView();
    // }
  };
  const handlePublishClick = async () => {
    if (!state.code) {
      return
    }
    scrollToSection("loading")
    state.loading = `Publishing App ${state.projectName} ${state.appName} ${state.app_version_dev} ...`
    setState({ ...state });
    if (!state.appAlias) {
      if (state.projectAppList) {
        let currentApp = state.projectAppList.find(app => app[1] === state.appName)
        if (currentApp) {
          state.appAlias = currentApp[3]
          setState({ ...state })
        }
      }
      if (!state.appAlias) {
        state.appAlias = faker.internet.domainWord()
        setState({ ...state })
      }
    }
    // setCompletion('Loading...')
    // make a request to the backend endpoint with the prompt as a query parameter
    const response = await axios.post(
      `${backendApiBaseUrl}/save_project_app`,
      { projectName: state.projectName, appName: state.appName, user_id: userId, app_alias: state.appAlias, app_code: state.code, app_version: state.app_version_dev },
      {
        headers: {
          'Authorization': 'Bearer ' + await getToken()
        }
      }
    );
    // let data = JSON.parse(response.data)
    // for (let item of data) {
    //   projectOptions.push({value: item[0], label: item[0]})
    //   appOptions.push({value: item[1], label: item[1]})
    // }    
    state.projectAppList = null
    state.loading = ""
    state.app_version = state.app_version_dev
    setState({ ...state });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleClear = () => {
    console.log("handleClear")
    console.log(state.code)
    state.loading = "Clearing App..."
    state.code = "";
    state.completion = "";
    state.app_version_dev = ""
    setState({ ...state });
    state.loading = ""
    setState({ ...state });

  };

  const [toggleShare, setToggleShare] = useState(false);
  const handleShare = () => {
    navigator.clipboard.writeText(state.code);
  };

  const recreate = () => {
    console.log("recreate")
    state.code = "";
    state.completion = "";
    state.app_version_dev = ""
    // setState({ ...state});    
    console.log(state.code)
    document.getElementById('submitCompletion').click();
  };

  const prompt = () => {
    console.log("prompt")
    scrollToSection("prompt-text")
    // setState({ ...state});    
    // console.log(state.code)
  };

  const saveCode = () => {
    console.log("saveCode")
    document.getElementById('submitCompletion').click();
  }
  const [toggleImprovePrompt, setToggleImprovePrompt] = useState(false);
  const [promptImprovement, setPromptImprovement] = useState("");
  const handleImprovePrompt = (improvement) => {
    console.log(`improvePrompt ${improvement}`)
    state.prompt = state.prompt + "\n" + "---" + "\n" + improvement
    document.getElementById('submitCompletion').click();
    setToggleImprovePrompt(false)
  }

  const [toggleThemeSelect, setToggleThemeSelect] = useState(false);

  const [currentTheme, setCurrentTheme] = useState(null);

  const handleThemeSelect = (event) => {
    var a = event.currentTarget;
    var theme = a.getAttribute("data-set-theme")
    var mystr = state.code
    var res = mystr.match(/data-theme=\".*\"/g);
    var str = mystr.replace(res, `data-theme="${theme}"`);
    state.code = str
    console.log(a.innerHTML)
    setCurrentTheme(a.innerHTML)
    setState({ ...state })
    setToggleThemeSelect(false)
  }


  const [isFullscreen, setIsFullscreen] = useState(false);

  const [isFullscreenCode, setIsFullscreenCode] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    scrollToSection("appScreenBuilder")
  };

  const toggleFullscreenCode = () => {
    setIsFullscreenCode(!isFullscreenCode);
  };

  // const handleSubmit = (e) => {
  //   // Handle Validation on submit
  //   console.log("handleSubmit2"+JSON.stringify(state))
  //   Validator.validate(state)
  //     .then(data => {
  //       alert("Success");
  //       // setState(initState);
  //     })
  //     .catch(errors => {
  //       console.log("error handleSubmit"+JSON.stringify(errors))
  //       // setState({ errors: errors });
  //       state["errors"] = errors
  //     });
  // }  
  const handleSubmit2 = (e) => {
    // Handle Validation on submit
    console.log("handleSubmit2" + JSON.stringify(state))
  }

  const handleDirty = (e) => {
    // Handle Validation on touch

    const { name, value } = e.target;

    const isValid = Validator[name].validate(value, {
      verbose: true,
      values: state
    });

    if (isValid !== true) {
      // setState({
      //   errors: { ...state.errors, [name]: isValid }
      // });
      state["errors"] = { ...state.errors, [name]: isValid };
    } else {
      // setState({
      //   errors: { ...state.errors, [name]: undefined }
      // });
      state["errors"] = { ...state.errors, [name]: undefined };
    }
  }
  const handleSelectCompletion = async (data) => {
    setCompletion(data.app_code);
    setCode(data.app_code);
    state.code = data.app_code;
    state.completion = data.app_code;
    state.app_version_dev = data.app_version;
    state.latestVersions = [];
    setState({ ...state });
    // state['code'] = response.data

  }

  const handleAiChatIntegrationClick = () => {
    setState({ ...state, hasAiChatIntegration: !state.hasAiChatIntegration })
  }

  const handleEmailSenderIntegrationClick = () => {
    setState({ ...state, hasEmailSenderIntegration: !state.hasEmailSenderIntegration })
  }  

  const handlePromptChange = (e) => {
    console.log("handlePromptChange");
    // console.log("handleStateChange"+JSON.stringify(e.target))
    // Handle Validation on touch

    const { name, value } = e.target;
    state[name] = value;
    setState({ ...state, prompt: value });
    console.log("handlePromptChange" + name + "-" + value + "-" + state.prompt);
  }

  // const handleStateChange = (e) => {
  //   console.log("handleStateChange");
  //   // console.log("handleStateChange"+JSON.stringify(e.target))
  //   // Handle Validation on touch

  //   const { name, value } = e.target;
  //   state[name] = value;
  //   console.log("handleStateChange"+name+"-"+value);
  // }
  // const Input = props => <components.Input {...props} required={props.selectProps.required} />

  const handleShowImprovePrompt = (state) => {
    state.showImprovePrompt = true;
    setState({ ...state });
    navigate("/editor")
    // state['code'] = response.data

  }

  useEffect(() => {
    console.log(`${state.projectName}-${state.appName}-${state.loading}`)
  }, [state.loading]);

  useEffect(() => {
    // window.scrollTo(0, document.body.scrollHeight)
  }, [isFullscreenCode, isFullscreen]);

  useEffect(() => {
    // window.scrollTo(0, document.body.scrollHeight)
    if (state.prompt) {
      errors.prompt = ""
    }
  }, [state.prompt]);


  // useEffect(() => {
  //   if (document.getElementById('promptImprove')) {
  //     document.getElementById('promptImprove').focus()
  //   }
  // }, [toggleImprovePrompt]);



  if (!isLoaded || !isSignedIn) {
    return null;
  }
  console.log(`${state.projectName}-${state.appName}`)
  return (
    <>
      <div id="prompt-feature" className='w-full md:mt-4'>
        <h1 id='prompt-text' className="text-3xl font-bold mb-8 text-center">Enter The Prompt To Create Your App</h1>
        <div className="flex flex-col md:flex-row items-center mb-4 space-x-0 md:space-x-4 space-y-4 md:space-y-0 w-full justify-center">
          <div className="flex flex-col mb-5 w-full md:w-2/6">
            <a data-tooltip-id="my-tooltip3" data-tooltip-delay-hide={1000} className="w-full">
              <Controller
                // onBlur={handlePromptChange}
                render={({ field }) =>
                  <textarea {...field}
                    value={state.prompt}
                    onFocus={handleSubmit(() => { })}
                    onChange={v => { handlePromptChange(v); field.onChange(v) }}
                    // onBlur={v=>field.onBlur(handlePromptChange)}
                    // onBlur={field.onBlur(v=>{setState({...state, prompt: v?.target.value})})}
                    className="read-only:bg-gray-100 w-full md:w-96 py-2 px-4 rounded-lg border-gray-400 border-2 focus:outline-none focus:border-indigo-500 shadow-md"
                    rows={8}
                    readOnly={state.code ? true : false}
                  // {...register("prompt", { required: true })} aria-invalid={errors.prompt ? "true" : "false"}
                  >
                  </textarea>
                }
                name="prompt"
                id="prompt"
                control={control}
                rules={{ required: true }}

              />
            </a>
            <ReactTooltip 
              id="my-tooltip3"
              isOpen={!!errors.prompt} 
              place={"bottom-start"}
              offset={5}
              style={{ padding: 0, marginRight: 0 }}
            >
              <div className='h-4 m-1'>
                {errors.prompt && <p className='text-red-700 text-xs'>Type a prompt before generating your App.</p>}
              </div>
            </ReactTooltip>
          </div>
          {/* <button onClick={handleSubmit(handleSubmit2)}
            className="w-full md:w-auto ml-0 md:ml-4 py-2 px-4 rounded-lg bg-gradient-to-r from-indigo-500 to-indigo-600 hover:from-indigo-600 hover:to-indigo-700 focus:from-indigo-600 focus:to-indigo-700 text-white shadow-md">
            Improve
          </button> */}
          <div className='w-full md:w-20 flex flex-row md:flex-col items-center'>
            <div className='mx-4 w-full md:my-4'>
              {(!state.code || state.appTemplate) &&
                <button id='submitCompletion' disabled={state.loading !== undefined && state.loading != ''} onClick={handleSubmit(() => utils.handleCompletionClick(state))}
                  className="disabled:opacity-50 w-full md:w-autox ml-0 md:ml-4 py-2 px-2 rounded-lg bg-gradient-to-r from-indigo-700 to-indigo-900 hover:from-indigo-800 hover:to-indigo-900 focus:from-indigo-800 focus:to-indigo-900 text-white shadow-md">
                  Create
                </button>
              }
              {state.code && !state.appTemplate &&
                <button id='submitCompletion2' disabled={state.loading !== undefined && state.loading != ''} onClick={() => handleShowImprovePrompt(state)}
                  className="disabled:opacity-50 w-full md:w-autox ml-0 md:ml-4 py-2 px-2 rounded-lg bg-gradient-to-r from-indigo-700 to-indigo-900 hover:from-indigo-800 hover:to-indigo-900 focus:from-indigo-800 focus:to-indigo-900 text-white shadow-md">
                  Update
                </button>
              }
            </div>
            <div className='mx-4 w-full md:my-4'>
              <button id='newApp' disabled={state.loading !== undefined && state.loading != ''} onClick={() => handleShowNewApp(state)}
                className="disabled:opacity-50 w-full md:w-autox ml-0 md:ml-4 py-2 px-2 rounded-lg bg-gradient-to-r from-indigo-700 to-indigo-900 hover:from-indigo-800 hover:to-indigo-900 focus:from-indigo-800 focus:to-indigo-900 text-white shadow-md">
                New
              </button>

            </div>
          </div>
          <div className='md:px-8'>
            <div>
            <label>
              <input name='hasAiChatIntegration' className="mx-1" type="checkbox" checked={state.hasAiChatIntegration} onChange={handleAiChatIntegrationClick} />
              Integrate with AI Chat?
            </label>
            </div>
            <div className='hidden'>
            <label>
              <input name='hasEmailSenderIntegration' className="mx-1" type="checkbox" checked={state.hasEmailSenderIntegration} onChange={handleEmailSenderIntegrationClick} />
              Integrate E-mail Sender?
            </label> 
            </div>           
          </div>
        </div>
      </div>
    </>
  );
}

export default Builder;
