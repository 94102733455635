import { Field, Schema } from "v4f";

// Create Complex validation, with rules chaining

export default Schema(
  {
    projectName: Field()
      .string()
      .required({ message: "this field is required" }),
    appName: Field()
      .string()
      .required({ message: "this field is required" }),
    prompt: Field()
      .string()
      .required({ message: "this field is required" })      
  },
  { verbose: true, async: true }
);
